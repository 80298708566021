<template>
  <div class="content__body">
    <div class="personal-info">
      <div class="personal-info__block">
        <div class="personal-info__photo">
          <img v-if="user_info.crop_url" :src="user_info.crop_url" alt="User photo">
          <img v-else src="@/assets/img/user-empty-photo.svg" alt="Slug">
        </div>
        <div class="personal-info__about">
          <div class="personal-info__name">{{ user_info.last_name }} {{ user_info.first_name }} {{ user_info.patronymic }}</div>
          <div class="personal-info__block">
            <div class="personal-info__line">
              <b>Дата регистрации:</b>
              <span>{{ user_info.date_joined }}</span>
            </div>
            <div class="personal-info__line">
              <b>Дата рождения:</b>
              <span>{{ user_info.dob }} (возраст: {{ age }})</span>
            </div>
            <div class="personal-info__line">
              <b>Пол:</b>
              <span>{{ user_info.gender ? 'мужской' : 'женский' }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="personal-info__cols">
        <div v-if="user_info.phone" class="personal-info__line">
          <b>Телефон:</b>
          <span>{{ user_info.phone }}</span>
        </div>
        <div v-if="user_info.email" class="personal-info__line">
          <b>Электронная почта:</b>
          <span>{{ user_info.email }} <img v-if="user_info.email_is_verified" src="@/assets/img/profile/check-theme-icon.svg" alt=""></span>
        </div>
      </div>
    </div>
    <template v-if="user_info.profile">
      <div v-if="user_info.profile.have_soc" class="socials-block">
        <template v-for="(item, name) in user_info.profile.socials">
          <div v-if="item" :key="name" class="socials-block__line">
            <img :src="require(`@/assets/img/accordion/${name}.svg`)" alt="">
            <a :href="item" target="_blank">{{ item }}</a>
          </div>
        </template>
      </div>
      <app-accordion title="Адреса">
        <div v-if="user_info.profile.addresses.registration_address_text" class="accordion-view">
          <div class="accordion-view__block">
            <div class="accordion-view__line">
              <b>Адрес регистрации:</b> <span>{{ user_info.profile.addresses.registration_address_text }}</span>
            </div>
            <div class="accordion-view__line">
              <b>Адрес проживания:</b> <span>{{ user_info.profile.addresses.residence_address_text }}</span>
            </div>
          </div>
        </div>
        <div v-else>Данные еще не заполнены</div>
      </app-accordion>
      <app-accordion title="Паспорт">
        <div v-if="user_info.profile.passport.passport_number" class="accordion-view">
          <div class="accordion-view__block">
            <div v-if="user_info.profile.citizenship.citizenship" class="accordion-view__line">
              <b>Гражданство:</b> <span>{{ user_info.profile.citizenship.citizenship }}</span>
            </div>
            <div v-if="user_info.profile.passport.passport_series" class="accordion-view__line">
              <b>Серия:</b> <span>{{ user_info.profile.passport.passport_series }}</span>
            </div>
            <div v-if="user_info.profile.passport.passport_number" class="accordion-view__line">
              <b>Номер:</b> <span>{{ user_info.profile.passport.passport_number }}</span>
            </div>
            <div v-if="user_info.profile.passport.passport_fms_name" class="accordion-view__line">
              <b>Кем выдан:</b> <span>{{ user_info.profile.passport.passport_fms_name }}</span>
            </div>
            <div v-if="user_info.profile.passport.passport_date" class="accordion-view__line">
              <b>Дата выдачи:</b> <span>{{ user_info.profile.passport.passport_date }}</span>
            </div>
            <div v-if="user_info.profile.passport.passport_date_end" class="accordion-view__line">
              <b>Срок действия:</b> <span>{{ user_info.profile.passport.passport_date_end }}</span>
            </div>
            <div v-if="user_info.profile.passport.passport_birthplace" class="accordion-view__line">
              <b>Место рождения:</b> <span>{{ user_info.profile.passport.passport_birthplace }}</span>
            </div>
            <div v-if="user_info.profile.passport.passport_main_page_url" class="accordion-view__line">
              <a :href="user_info.profile.passport.passport_main_page_url" target="_blank" class="link">Скан-копия основного разворота паспорта</a>
            </div>
            <div v-if="user_info.profile.passport.passport_second_page_url" class="accordion-view__line">
              <a :href="user_info.profile.passport.passport_second_page_url" target="_blank" class="link">
                Скан-копия разворота с регистрацией или временная регистрация
              </a>
            </div>
            <div v-if="user_info.profile.passport.visa_url" class="accordion-view__line">
              <a :href="user_info.profile.passport.visa_url" target="_blank" class="link">
                Скан-копия страницы с действующей визой
              </a>
            </div>
          </div>
        </div>
        <div v-else>Данные еще не заполнены</div>
      </app-accordion>
      <app-accordion title="Карьера">
        <div v-if="user_info.career && user_info.career.length" class="accordion-view">
          <div v-for="(item, index) in user_info.career" :key="index" class="accordion-view__block">
            <div class="accordion-view__line">
              <b>Название организации:</b> <span>{{ item.org_text }}</span>
            </div>
            <div class="accordion-view__line">
              <b>Должность:</b> <span>{{ item.position }}</span>
            </div>
            <div class="accordion-view__line">
              <b>Период работы:</b> <span>{{ item.date_start }} - {{ item.date_end ? item.date_end : 'по н.в.' }}</span>
            </div>
          </div>
        </div>
        <div v-else>Данные еще не заполнены</div>
      </app-accordion>
      <app-accordion title="Документы">
        <div v-if="user_info.doc && user_info.doc.length" class="accordion-view">
          <div v-for="(item, index) in user_info.doc" :key="index" class="accordion-view__block">
            <div class="accordion-view__line">
              <b>Вид документа:</b> <span>{{ item.type_name }}</span>
            </div>
            <div class="accordion-view__line">
              <b>Серия:</b> <span>{{ item.series }}</span>
            </div>
            <div class="accordion-view__line">
              <b>Номер:</b> <span>{{ item.number }}</span>
            </div>
            <div class="accordion-view__line">
              <b>Кем выдан:</b> <span>{{ item.issued_by }}</span>
            </div>
            <div class="accordion-view__line">
              <b>Скан-копия:</b>&nbsp;
              <a v-if="item.scan_url" :href="item.scan_url" target="_blank" class="link">Посмотреть</a>
              <img v-else src="@/assets/img/profile/not-icon.svg" alt="">
            </div>
          </div>
        </div>
        <div v-else>Данные еще не заполнены</div>
      </app-accordion>
      <app-accordion title="Образование">
        <div v-if="user_info.edu && user_info.edu.length" class="accordion-view">
          <div v-for="(item, index) in user_info.edu" :key="index" class="accordion-view__block">
            <div class="accordion-view__line">
              <b>Уровень образования:</b> <span>{{ item.level_name }}</span>
            </div>
            <div class="accordion-view__line">
              <b>Учебное заведение:</b> <span>{{ item.org_text }}</span>
            </div>
            <div class="accordion-view__line">
              <b>Период обучения:</b> <span>{{ item.date_start }} - {{ item.date_end ? item.date_end : 'по н.в.' }}</span>
            </div>
          </div>
        </div>
        <div v-else>Данные еще не заполнены</div>
      </app-accordion>
      <app-accordion title="Владения языками">
        <div v-if="user_info.lang && user_info.lang.length" class="accordion-view">
          <div v-for="(item, index) in user_info.lang" :key="index" class="accordion-view__block">
            <div class="accordion-view__line">
              <b>Язык:</b> <span>{{ item.lang_name }}</span>
            </div>
            <div class="accordion-view__line">
              <b>Уровень владения:</b> <span>{{ item.level_name }}</span>
            </div>
          </div>
        </div>
        <div v-else>Данные еще не заполнены</div>
      </app-accordion>
      <app-accordion title="Прочее">
        <div v-if="user_info.other && user_info.other.have_other" class="accordion-view">
          <div class="accordion-view__block">
            <div v-if="user_info.other.about" class="accordion-view__line">
              <b>О себе:</b> <span>{{ user_info.other.about }}</span>
            </div>
            <div v-if="user_info.other.clothing_size" class="accordion-view__line">
              <b>Размер одежды:</b> <span>{{ user_info.other.clothing_size }}</span>
            </div>
            <div v-if="user_info.other.shoe_size" class="accordion-view__line">
              <b>Размер обуви:</b> <span>{{ user_info.other.shoe_size }}</span>
            </div>
            <div v-if="user_info.other.status_text" class="accordion-view__line">
              <b>Статус:</b> <span>{{ user_info.other.status_text }}</span>
            </div>
            <div v-if="user_info.other.marital" class="accordion-view__line">
              <b>Семейное положение:</b> <span>{{ user_info.other.marital ? 'женат / замужем' : 'холост / не замужем' }}</span>
            </div>
            <div v-if="user_info.other.is_public" class="accordion-view__line">
              <b>Публичность профиля:</b> <span>{{ user_info.other.is_public ? 'да' : 'нет' }}</span>
            </div>
          </div>
        </div>
        <div v-else>Данные еще не заполнены</div>
      </app-accordion>
    </template>
  </div>
</template>

<script>
import { differenceInCalendarYears, parse } from 'date-fns'

export default {
  name: 'UserDetail',
  data() {
    return {
      user_info: {
        profile: {
          socials: {},
          addresses: {},
          citizenship: {},
          passport: {},
        }
      },
      age: 20
    }
  },
  mounted() {
    this.$store.dispatch('admin/users/GET_DATA', this.$route.params.id)
      .then(response => {
        this.user_info = response.data
        this.onCheckBirth()
      })
  },
  methods: {
    onCheckBirth() {
      const date = parse(this.user_info.dob, 'dd.MM.yyyy', new Date())
      this.age = differenceInCalendarYears(new Date(), date)
    },
  }
}
</script>

<style lang="sass" scoped>

</style>
